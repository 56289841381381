import { Injectable, signal, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { } from '@awo-care/shared-core';
import { environment } from '@awo-care/shared-auth';
export interface Language {
    code: string;
    name: string;
}

const LANGUAGE_KEY = 'preferredLanguage';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private document = inject(DOCUMENT);

    availableLanguages: Language[] = [
        { code: 'de', name: 'Deutsch' },
        { code: 'en', name: 'English' }
    ];

    currentLanguage = signal<Language>(this.getInitialLanguage());

    constructor() {
        if (environment.production) this.checkAndRedirect();
    }

    private getInitialLanguage(): Language {
        // First check localStorage
        const storedLang = localStorage.getItem(LANGUAGE_KEY);
        if (storedLang) {
            const lang = this.availableLanguages.find(l => l.code === storedLang);
            if (lang) return lang;
        }

        // Then check current locale from html lang attribute
        const currentLocale = this.document.documentElement.lang;
        const lang = this.availableLanguages.find(l => l.code === currentLocale);

        // Finally default to German
        return lang || this.availableLanguages[0];
    }

    private checkAndRedirect() {
        const storedLang = localStorage.getItem(LANGUAGE_KEY);
        if (!storedLang) {
            // If no stored preference, save current language
            localStorage.setItem(LANGUAGE_KEY, this.currentLanguage().code);
            return;
        }

        // Get current path language
        const currentPath = window.location.pathname;
        const currentLangMatch = currentPath.match(/^\/(de|en)/);
        const currentLangCode = currentLangMatch ? currentLangMatch[1] : null;

        // If stored preference differs from current path language, redirect
        if (currentLangCode !== storedLang) {
            const newPath = `/${storedLang}${currentPath.startsWith('/de') || currentPath.startsWith('/en') ? currentPath.substring(3) : currentPath}`;
            // Use replace to avoid adding to browser history
            window.location.replace(newPath);
        }
    }

    setLanguage(language: Language) {
        if (language.code === this.currentLanguage().code) return;

        // Store preference
        localStorage.setItem(LANGUAGE_KEY, language.code);

        // Reload page with new locale
        const currentPath = window.location.pathname;
        const newPath = `/${language.code}${currentPath.startsWith('/de') || currentPath.startsWith('/en') ? currentPath.substring(3) : currentPath}`;
        window.location.pathname = newPath;
    }

    getLanguages(): Language[] {
        return this.availableLanguages;
    }
}